import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { getHeaderDropdown } from '../selectors/dropdowns';

import { createUpdatePublicViewTemplateData } from '../actions/shop';
import { closePopup } from '../actions/popup';
import { createUploadFile } from '../actions/file';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Img from './Img';

class SelectShopHeaderPopup extends Component {

  render() {
    const { onClosePopup, onChangeHeader, onUploadHeader, loading, index, company_id, headers } = this.props;

    return (
      <div id="replace-header-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Select Header</h3>
          </div>
        </div>
        <div className="row popup-content">
          {headers.map(h =>
            <div key={h.file_id} className="small-12 medium-6 columns header-banner" style={{ cursor: 'pointer' }} onClick={e => onChangeHeader(getImageSrc(h, 'headers'))}>
              <Img src={getImageSrc(h, 'headers')}/>
            </div>
          )}
          <div className="small-12 medium-6 columns header-banner">
            <div className="new-header">
              <Dropzone onDrop={onUploadHeader(company_id)} style={{border: 'none'}} multiple={false} accept="image/*">
                <>{loading ?
                  <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                : 'Upload Header'}</>
              </Dropzone>
            </div>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    headers: getHeaderDropdown(state),
    company_id: state.identity.company_id,
    loading: state.display.loading.upload_file
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeHeader: value => {
      dispatch(createUpdatePublicViewTemplateData(ownProps.shop_id, 'SHOP', ownProps.public_view_template_id, ownProps.name, value));
      return dispatch(closePopup());
    },
    onUploadHeader: company_id => files => {
      dispatch(createUploadFile(company_id, 'TENANT-HEADER', files[0]));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    },
  };
};

const ConnectedSelectShopHeaderPopup = connect(mapStateToProps, mapDispatchToProps)(SelectShopHeaderPopup);
export default ConnectedSelectShopHeaderPopup;

